
import request from '@/services/request'

// 获取环球影院订单列表
export function fetchOrderListByUniversalStudios(data) {
  return request({
    url: '/app/YingCheng/orderList',
    method: 'post',
    data,
    prefix: 'yuelvhui'
  })
}

// 获取元宇宙订单列表
export function fetchnftlis(data) {
  return request({
    url: 'app/nft/lis',
    method: 'post',
    data,
    prefix: ''
  })
}
